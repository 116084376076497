import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { FiMaximize2 } from "react-icons/fi";

import AOS from "aos";
import "aos/dist/aos.css";


// - Import Data
import useStaticData from "../../hooks/useStaticData";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import Video from "../../assets/videos/dji.mp4";

import "./index.scss";

const Home = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null); // Reference to the video element

  const data = useStaticData();
  const pageData = data?.seo.home;
  const schemaData = pageData?.schema;
  const words = data?.hero?.words || [];

  useDocumentTitle(
    "Full-Stack Developer Based in Sint-Niklaas, Belgium | Yani Degrande"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 5000); // Change word every 5 seconds

    return () => clearInterval(interval);
  }, [words.length]);

  // Add fullscreen change event listeners
  useEffect(() => {
    const handleFullscreenChange = () => {
      // Check if the browser is in fullscreen mode
      const isFullscreenActive =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;
      setIsFullScreen(!!isFullscreenActive); // Update state based on fullscreen status
    };

    // Attach event listeners for fullscreen changes
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      // Clean up event listeners when the component unmounts
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (!isFullScreen) {
        // Enter fullscreen
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen(); // Firefox
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen(); // Chrome, Safari and Opera
        } else if (videoElement.msRequestFullscreen) {
          videoElement.msRequestFullscreen(); // IE/Edge
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen(); // Chrome, Safari and Opera
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE/Edge
        }
      }
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  if (!data) {
    return (
      <section id="hero">
        <div className="hero__content">
          <div className="hero__loader"></div>
        </div>
      </section>
    );
  }

  return (
    <section id="hero">
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <meta property="og:title" content={pageData.ogTitle} />
        <meta property="og:description" content={pageData.ogDescription} />
        <meta property="og:image" content={pageData.ogImage} />
        <meta property="og:url" content={pageData.ogUrl} />
        <meta name="twitter:title" content={pageData.twitterTitle} />
        <meta
          name="twitter:description"
          content={pageData.twitterDescription}
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="hero__content flex-column">
        <div className="hero__content__header flex-column">
          <h1 data-aos="fade-down">
            <div className="hero-rotation">
              <div className="hero-rotation__text">
                {words.map((word, index) => (
                  <span
                    key={index}
                    className={`hero-rotation__word ${index === currentWordIndex ? "active" : ""
                      }`}
                    style={{
                      transform: `translateY(-${currentWordIndex * 100}%)`,
                      transition: "transform 0.5s ease",
                    }}
                  >
                    {word}
                  </span>
                ))}
              </div>
            </div>
            {` ${data.hero.heading}`}
          </h1>
          <p data-aos="fade-right">{data.hero.subheading}</p>
          <a href={data.hero.cta.url} className="cta-button" data-aos="fade-left">
            <span>{data.hero.cta.label}</span>
            <span>{data.hero.cta.label}</span>
          </a>
        </div>

        {/* Video with click to fullscreen */}
        <div className="video-container">
          <div className="icon-container">
            <FiMaximize2
              className="fullscreen-icon"
              onClick={handleVideoClick} // Add click event
            />
          </div>
          <video
            ref={videoRef}
            src={Video}
            autoPlay
            loop
            muted
            onClick={handleVideoClick} // Add click event
            className={isFullScreen ? "fullscreen" : ""}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default Home;
